function Choice({ advanceStep, setTipo }) {

    const handleChoice = (tipo) => {
        setTipo(tipo)
        advanceStep();
    }
    return (<>
        <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
        <p>Selecione abaixo para quem será a simulação.<br /><strong>É rápido e seguro!</strong></p>

        <div className="mx-auto mt-4">
            <div className="Perfis row" role="group" aria-label="Basic mixed styles example">
                <div className="offset-4 col-12 col-lg-4 my-2"><button type="button" className="btn ParaVoce w-100" onClick={() => handleChoice('PF')}>INICIAR</button></div>
            </div>
        </div>
    </>
    )
}

export default Choice;