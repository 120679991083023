import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { object, string, number, mixed, date } from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';

function Step5Empresa({ advanceStep, submissionId }) {
    const [formattedRendaMensal, setFormattedRendaMensal] = useState('');
    const [socioList, setSocioList] = useState([]);
    const fileRef1 = useRef(null);
    const fileRef2 = useRef(null);
    const fileRef3 = useRef(null);

    const formik = useFormik({
        initialValues: {
            nomeSocio: '',
            cpf: '',
            rg: '',
            orgaoEmissor: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            municipio: '',
            estado: '',
            telefoneFixo: '',
            telefoneCelular: '',
            email: '',
            politicamenteExposta: 'nao',
            descricaoPPE: '',
            estadoCivil: '',
            rendaMensal: '',
            administradorDesde: '',
            identidadeFrente: null,
            identidadeVerso: null,
            comprovanteEndereco: null
        },
        validationSchema: object({
            nomeSocio: string().required('O nome completo do sócio é obrigatório'),
            cpf: string().required('O CPF é obrigatório').matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido'),
            rg: string().required('O RG é obrigatório').matches(/^\d{2}\.\d{3}\.\d{3}-\d{1}$/, 'Formato de RG inválido (use 00.000.000-0)'),
            orgaoEmissor: string().required('O órgão emissor é obrigatório'),
            cep: string().required('O CEP é obrigatório').matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
            logradouro: string().required('O logradouro é obrigatório'),
            numero: string().required('O número é obrigatório').matches(/^\d+$/, 'O número deve conter apenas dígitos numéricos'),
            complemento: string(),
            bairro: string().required('O bairro é obrigatório'),
            municipio: string().required('O município é obrigatório'),
            estado: string().required('O estado é obrigatório'),
            telefoneFixo: string().required('O telefone fixo é obrigatório'),
            telefoneCelular: string().required('O telefone celular é obrigatório'),
            email: string().required('O e-mail é obrigatório').email('E-mail inválido'),
            politicamenteExposta: string().required('Informe se a pessoa é politicamente exposta'),
            descricaoPPE: string().when('politicamenteExposta', {
                is: 'sim',
                then: (schema) => schema.required('A descrição é obrigatória se a pessoa for politicamente exposta'),
                otherwise: (schema) => schema.nullable()
            }),
            estadoCivil: string().required('O estado civil é obrigatório'),
            rendaMensal: number().required('A renda mensal aproximada é obrigatória'),
            administradorDesde: date().required('A data administrador desde é obrigatória'),
            identidadeFrente: mixed().required('O documento de identidade frente é obrigatório')
                .test('fileType', 'Somente imagens e PDFs são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png' || value.type === 'application/pdf');
                }),
            identidadeVerso: mixed().required('O documento de identidade verso é obrigatório')
                .test('fileType', 'Somente imagens e PDFs são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png' || value.type === 'application/pdf');
                }),
            comprovanteEndereco: mixed().required('O comprovante de endereço é obrigatório')
                .test('fileType', 'Somente imagens e PDFs são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png' || value.type === 'application/pdf');
                })
        }),
        onSubmit: values => {
            setSocioList([...socioList, values]);
            formik.resetForm();
            setFormattedRendaMensal('');
            fileRef1.current.value = '';
            fileRef2.current.value = '';
            fileRef3.current.value = '';
        }
    });

    const handleCurrencyFormat = (event, field) => {
        let value = event.target.value.replace(/\D/g, '');
        value = value ? Number(value) / 100 : '';
        const formatted = value ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value) : '';

        if (field === 'rendaMensal') {
            setFormattedRendaMensal(formatted);
            formik.setFieldValue('rendaMensal', value.toString());
        }
    };

    const handleFileChange = (event, field) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue(field, file);
    };

    const handleFinalSubmit = async () => {
        if (socioList.length > 0) {
            const formData = new FormData();
            formData.append('id', submissionId);
            socioList.forEach((socio, index) => {
                formData.append(`socios[${index}].nomeSocio`, socio.nomeSocio);
                formData.append(`socios[${index}].cpf`, socio.cpf);
                formData.append(`socios[${index}].rg`, socio.rg);
                formData.append(`socios[${index}].orgaoEmissor`, socio.orgaoEmissor);
                formData.append(`socios[${index}].cep`, socio.cep);
                formData.append(`socios[${index}].logradouro`, socio.logradouro);
                formData.append(`socios[${index}].numero`, socio.numero);
                formData.append(`socios[${index}].complemento`, socio.complemento);
                formData.append(`socios[${index}].bairro`, socio.bairro);
                formData.append(`socios[${index}].municipio`, socio.municipio);
                formData.append(`socios[${index}].estado`, socio.estado);
                formData.append(`socios[${index}].telefoneFixo`, socio.telefoneFixo);
                formData.append(`socios[${index}].telefoneCelular`, socio.telefoneCelular);
                formData.append(`socios[${index}].email`, socio.email);
                formData.append(`socios[${index}].politicamenteExposta`, socio.politicamenteExposta == 'sim' ? true : false);
                formData.append(`socios[${index}].descricaoPPE`, socio.descricaoPPE);
                formData.append(`socios[${index}].estadoCivil`, socio.estadoCivil);
                formData.append(`socios[${index}].rendaMensal`, socio.rendaMensal);
                formData.append(`socios[${index}].identidadeFrente`, socio.identidadeFrente);
                formData.append(`socios[${index}].identidadeVerso`, socio.identidadeVerso);
                formData.append(`socios[${index}].comprovanteEndereco`, socio.comprovanteEndereco);
            });

            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/precadastro/pj/step5`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                advanceStep();
            } catch (error) {
                console.error('Erro ao enviar dados:', error);
            }
        }
    };

    const handleRemoveSocio = (index) => {
        const newSocioList = socioList.filter((_, i) => i !== index);
        setSocioList(newSocioList);
    };

    return (
        <>
            <h6 className="ParaEmpresa mx-auto mb-3">PARA SUA EMPRESA</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 mt-3" onSubmit={formik.handleSubmit}>
                <h3 className="mb-0">DADOS DOS SÓCIOS</h3>
                <div className="row">
                    <div className="col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome completo do sócio"
                            {...formik.getFieldProps('nomeSocio')}
                        />
                        {formik.touched.nomeSocio && formik.errors.nomeSocio ? (
                            <div className="text-danger">{formik.errors.nomeSocio}</div>
                        ) : null}
                    </div>
                    <div className="col-6">
                        <InputMask
                            mask="999.999.999-99"
                            className="form-control text-center"
                            placeholder="CPF"
                            {...formik.getFieldProps('cpf')}
                        />
                        {formik.touched.cpf && formik.errors.cpf ? (
                            <div className="text-danger">{formik.errors.cpf}</div>
                        ) : null}
                    </div>
                    <div className="col-6">
                        <InputMask
                            mask="99.999.999-9"
                            className="form-control"
                            placeholder="RG"
                            {...formik.getFieldProps('rg')}
                        />
                        {formik.touched.rg && formik.errors.rg ? (
                            <div className="text-danger">{formik.errors.rg}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Órgão Emissor"
                            {...formik.getFieldProps('orgaoEmissor')}
                        />
                        {formik.touched.orgaoEmissor && formik.errors.orgaoEmissor ? (
                            <div className="text-danger">{formik.errors.orgaoEmissor}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <InputMask
                            mask="99999-999"
                            className="form-control"
                            placeholder="CEP"
                            {...formik.getFieldProps('cep')}
                        />
                        {formik.touched.cep && formik.errors.cep ? (
                            <div className="text-danger">{formik.errors.cep}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Logradouro"
                            {...formik.getFieldProps('logradouro')}
                        />
                        {formik.touched.logradouro && formik.errors.logradouro ? (
                            <div className="text-danger">{formik.errors.logradouro}</div>
                        ) : null}
                    </div>
                    <div className="col-6 col-md-4 col-lg-4">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Número"
                            {...formik.getFieldProps('numero')}
                        />
                        {formik.touched.numero && formik.errors.numero ? (
                            <div className="text-danger">{formik.errors.numero}</div>
                        ) : null}
                    </div>
                    <div className="col-6 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Complemento"
                            {...formik.getFieldProps('complemento')}
                        />
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bairro"
                            {...formik.getFieldProps('bairro')}
                        />
                        {formik.touched.bairro && formik.errors.bairro ? (
                            <div className="text-danger">{formik.errors.bairro}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Município"
                            {...formik.getFieldProps('municipio')}
                        />
                        {formik.touched.municipio && formik.errors.municipio ? (
                            <div className="text-danger">{formik.errors.municipio}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <select
                            className="form-select mt-2 text-center"
                            aria-label="Estado"
                            {...formik.getFieldProps('estado')}
                        >
                            <option value="" label="Selecione o Estado" />
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                        {formik.touched.estado && formik.errors.estado ? (
                            <div className="text-danger">{formik.errors.estado}</div>
                        ) : null}
                    </div>

                    <div className="col-md-auto col-md-4 col-lg-4">
                        <InputMask
                            mask="(99) 9999-9999"
                            className="form-control"
                            placeholder="Telefone Fixo"
                            {...formik.getFieldProps('telefoneFixo')}
                        />
                        {formik.touched.telefoneFixo && formik.errors.telefoneFixo ? (
                            <div className="text-danger">{formik.errors.telefoneFixo}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <InputMask
                            mask="(99) 99999-9999"
                            className="form-control"
                            placeholder="Telefone Celular"
                            {...formik.getFieldProps('telefoneCelular')}
                        />
                        {formik.touched.telefoneCelular && formik.errors.telefoneCelular ? (
                            <div className="text-danger">{formik.errors.telefoneCelular}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="E-mail"
                            {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>

                    <div className="col-12 col-md-6 col-lg-12 mt-lg-3 my-3 my-lg-auto">
                        <p className="mb-0">Pessoa Politicamente Exposta</p>
                        <div className="col-12">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="politicamenteExposta"
                                    id="inlineRadio1"
                                    value="sim"
                                    checked={formik.values.politicamenteExposta === 'sim'}
                                    onChange={() => formik.setFieldValue('politicamenteExposta', 'sim')}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Sim</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="politicamenteExposta"
                                    id="inlineRadio2"
                                    value="nao"
                                    checked={formik.values.politicamenteExposta === 'nao'}
                                    onChange={() => formik.setFieldValue('politicamenteExposta', 'nao')}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Não</label>
                            </div>
                        </div>
                        {formik.values.politicamenteExposta === 'sim' && (
                            <textarea className="form-control mb-3" id="descricaoPPE" rows="2" placeholder='Descreva em poucas palavras' {...formik.getFieldProps('descricaoPPE')}></textarea>
                        )}
                        {formik.touched.descricaoPPE && formik.errors.descricaoPPE ? (
                            <div className="text-danger">{formik.errors.descricaoPPE}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-6 col-lg-6">
                        <select
                            className="form-select"
                            aria-label="Estado civil"
                            {...formik.getFieldProps('estadoCivil')}
                        >
                            <option value="" label="Selecione o estado civil" />
                            <option value="C" label="CASADO(A)" />
                            <option value="S" label="SOLTEIRO(A)" />
                            <option value="D" label="DIVORCIADO(A)" />
                            <option value="J" label="SEPARADO(A) JUDICIALMENTE" />
                            <option value="Q" label="DESQUITADO(A)" />
                            <option value="V" label="VIÚVO(A)" />
                            <option value="U" label="UNIÃO ESTÁVEL" />
                        </select>
                        {formik.touched.estadoCivil && formik.errors.estadoCivil ? (
                            <div className="text-danger">{formik.errors.estadoCivil}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-6 col-lg-6 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Renda mensal aproximada"
                            onChange={(event) => handleCurrencyFormat(event, 'rendaMensal')}
                            value={formattedRendaMensal}
                        />
                        {formik.touched.rendaMensal && formik.errors.rendaMensal ? (
                            <div className="text-danger">{formik.errors.rendaMensal}</div>
                        ) : null}
                    </div>
                    <div className="rcol-md-auto col-md-6 col-lg-6 mb-3">
                        
                            <label htmlFor="">Administrador da empresa desde:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="administradorDesde"
                                placeholder="Data de inicio da administração"
                                {...formik.getFieldProps('administradorDesde')}
                            />
                            {formik.touched.administradorDesde && formik.errors.administradorDesde ? (
                                <div className="text-danger">{formik.errors.administradorDesde}</div>
                            ) : null}
                        
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-6">
                        <label htmlFor="identidadeFrente" className="form-label"><strong>IDENTIDADE FRENTE</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            id="identidadeFrente"
                            accept="image/jpeg, image/png, application/pdf"
                            onChange={(event) => handleFileChange(event, 'identidadeFrente')}
                            ref={fileRef1}
                        />
                        {formik.touched.identidadeFrente && formik.errors.identidadeFrente ? (
                            <div className="text-danger">{formik.errors.identidadeFrente}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-6 my-3 my-lg-auto">
                        <label htmlFor="identidadeVerso" className="form-label"><strong>IDENTIDADE VERSO</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            id="identidadeVerso"
                            accept="image/jpeg, image/png, application/pdf"
                            onChange={(event) => handleFileChange(event, 'identidadeVerso')}
                            ref={fileRef2}
                        />
                        {formik.touched.identidadeVerso && formik.errors.identidadeVerso ? (
                            <div className="text-danger">{formik.errors.identidadeVerso}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-12 mt-lg-3">
                        <label htmlFor="comprovanteEndereco" className="form-label"><strong>COMPROVANTE DE ENDEREÇO</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            id="comprovanteEndereco"
                            accept="image/jpeg, image/png, application/pdf"
                            onChange={(event) => handleFileChange(event, 'comprovanteEndereco')}
                            ref={fileRef3}
                        />
                        {formik.touched.comprovanteEndereco && formik.errors.comprovanteEndereco ? (
                            <div className="text-danger">{formik.errors.comprovanteEndereco}</div>
                        ) : null}
                    </div>

                    <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                        <button
                            type="submit"
                            className="btn btn-secondary BtnM"
                        >
                            <i className="bi bi-plus-circle me-2"></i>Salvar Sócio
                        </button>
                    </div>

                </div>
            </form>

            {socioList.length > 0 && (
                <div className="mt-4">
                    <h4>Lista de Sócios Adicionados:</h4>
                    <ul className="list-group">
                        {socioList.map((socio, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                {socio.nomeSocio} - {socio.cpf}
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveSocio(index)}>Remover</button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {socioList.length > 0 && (
                <div className="mt-4">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleFinalSubmit}
                    >
                        Finalizar e Avançar
                    </button>
                </div>
            )}
        </>
    );
}

export default Step5Empresa;
